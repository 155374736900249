
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import SatisBrasil from '../views/SatisBrasilView.vue'
import Produtos from '../views/ProdutosView.vue'
import Servicos from '../views/ServicosView.vue'
import DicasSaude from '../views/DicasSaudeView.vue'
import TemperoSatis from '../views/TemperoSatisView.vue'
import Blog from '../views/BlogView.vue'
import Contato from '../views/ContatoView.vue'
import Informacoes from '../views/InformacoesView.vue'
import ServicoDetalhe from '../views/ServicoDetalheView.vue'
import BlogDetalhe from '../views/BlogDetalheView.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/satisbrasil', name: 'satisbrasil', component: SatisBrasil },
  { path: '/produtos/:id?/:nome?', name: 'produtos', component: Produtos },
  { path: '/servicos', name: 'servicos', component: Servicos },
  { path: '/dicassaude', name: 'dicassaude', component: DicasSaude },
  { path: '/temperosatis/:id?/:nome?', name: 'temperosatis', component: TemperoSatis },
  { path: '/blog', name: 'blog', component: Blog },
  { path: '/contato', name: 'contato', component: Contato },
  { path: '/informacoes/:id/:nome', name: 'informacoes', component: Informacoes },
  { path: '/servicodetalhe/:id/:nome', name: 'servicodetalhe', component: ServicoDetalhe },
  { path: '/blogdetalhe/:id/:nome', name: 'blogdetalhe', component: BlogDetalhe },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  window.scrollTo({ top:0,behavior:'smooth'});
  next();
});

export default router

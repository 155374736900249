<template>
  <div>
    <section class="hero-wrap hero-wrap-2" v-if="servtopo!=''"
    :style="{ backgroundImage: 'url(' + $imgURL + servtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ servtopo[0].nome }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter" style="padding-top: 70px;" v-if="cadservi!=''">
			<div class="container consult-wrap">
				<div class="row d-flex align-items-stretch">
					<div class="col-md-6 wrap-about align-items-stretch d-flex"  v-if="cadservi!='' && cadservi[0].institucionalImg!=''">
						<div class="img" :style="{ backgroundImage: 'url(' + $imgURL +  cadservi[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',minHeight:'400px' }"></div>
					</div>
					<div class="col-md-6 wrap-about  py-md-5 pl-md-5">
						<div class="heading-section mb-4">
							<h2 style="color:#333; padding-bottom: 30px;">{{cadservi[0].nome}}</h2>
						</div>
						<p v-html="cadservi[0].descricao1" style="text-align: justify; color: #000000;">
            </p>
            <div class="col-md-12" style="text-align: center; padding-top: 50px; font-size: 20px; font-weight: 700;">
          <span><router-link to="/servicos"> Voltar</router-link></span></div>
					</div>
				</div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'ServicoDetalheView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const nome = route.params.nome;
    return {
      id,
      nome
    };
  },
  components: {},
  data: () => ({
    ecosystem: [],
    servtopo:[],
    cadservi: [],

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: this.nome + ' Serviços Detalhe - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
    // Servicos Topo
    axios.get('/institucional/telas/servicostopo')
        .then(response => {
        
        this.servtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
        //serviços
        axios.get('/institucional/telas/cadastroservicos')
        .then(response => {
        
        this.cadservi = response.data.filter(x => x.institucionalId==this.id);

      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

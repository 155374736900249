<template>
  <div>
    <section class="hero-wrap hero-wrap-2" :style="{ backgroundImage: 'url(' + $imgURL + sobretopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ sobretopo[0].nome }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter">
			<div class="container consult-wrap">
				<div class="row d-flex align-items-stretch">
				
					<div class="col-md-12 wrap-about  py-md-5 pl-md-5">
						<div class="heading-section mb-4">
							<h2 style="color: #333; text-align: center;"> {{ sobredescricao[0].nome }}</h2>
						</div>
						<p v-html="sobredescricao[0].descricao2" style="text-align: justify;"></p>
            
						<div class="tabulation-2 mt-4">
							<ul class="nav nav-pills nav-fill d-md-flex d-block">
							  <li class="nav-item">
							    <a href="#" class="nav-link py-2" :class="{ active: activeItem === '1' }" @click.prevent="mostraQuadro('1')"><span class="ion-ios-home mr-2"></span>{{sobitens[0].nome}}</a>
							  </li>
							  <li class="nav-item px-lg-2"> 
							    <a href="#" class="nav-link py-2" :class="{ active: activeItem === '2' }" @click.prevent="mostraQuadro('2')"><span class="ion-ios-person mr-2"></span>{{sobitens[1].nome}}</a>
							  </li>
							  <li class="nav-item">
							    <a href="#" class="nav-link py-2" :class="{ active: activeItem === '3' }" @click.prevent="mostraQuadro('3')"><span class="ion-ios-mail mr-2"></span>{{sobitens[2].nome}}</a>
							  </li>
							</ul>
							<div class="tab-content bg-light rounded mt-2">
							  <div class="tab-pane container p-0 active" id="home1" v-if="quadroAtivo=='1'">
							  	<p v-html="sobitens[0].descricao1" style="text-align: justify;"></p>
							  </div>
							  <div class="tab-pane container p-0 active" id="home2" v-if="quadroAtivo=='2'">
							  	<p v-html="sobitens[1].descricao1" style="text-align: justify;"></p>
							  </div>
							  <div class="tab-pane container p-0 active" id="home3" v-if="quadroAtivo=='3'">
							  	<p v-html="sobitens[2].descricao1" style="text-align: justify;"></p>
							  </div>
							</div>
						</div>
    				<div class="row mt-5">
	          </div>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section testimony-section" style="padding-top: 70px;padding-bottom: 150px;">
      <div class="container-fluid px-md-5">
        <div v-if="titprodhome != ''" class="row justify-content-center mb-5">
          <div class="col-md-8 text-center heading-section ">
            <h2 class="mb-4" style="color: #333;">{{ titprodhome[0].nome }}</h2>
            <p v-html="titprodhome[0].descricao1" style="text-align: center; color: #000000;"></p>  
				<p style="color:#ab3331;"><router-link :to="titprodhome[0].linkBtn1">{{ titprodhome[0].textoBtn1 }}</router-link></p>
          </div>
        </div>
        <div class="row  justify-content-center">
          <div class="col-md-12">
			<carrosel></carrosel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import carrosel from './../components/PageCarrosel.vue'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'SatisBrasilView',
  components: {
	carrosel
  },
  data: () => ({
   
	quadroAtivo:'1',
	activeItem:'1',
	sobretopo: [],
	sobredescricao:[],
	sobitens: [],
	titprodhome: [],

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Satis Brasil'
			})
		},
	mostraQuadro(value){
		this.quadroAtivo=value;
		this.activeItem =value;
	}
  },
  created() {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
	 // sobreTopo
   this.carregaMetas();
	 axios.get('/institucional/telas/sobretopo')
        .then(response => {
        
        this.sobretopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	   // sobredescrição
	 axios.get('/institucional/telas/sobredescricao')
        .then(response => {
        
        this.sobredescricao = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	    // Sobre itens
      axios.get('/institucional/telas/itenssobre')
        .then(response => {
        
        this.sobitens = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	   //Titulo produto home
	   axios.get('/institucional/telas/tituloprodutoshome')
        .then(response => {
        
        this.titprodhome = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped></style>

<template>
  <div>
    <section class="hero-wrap hero-wrap-2" v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
	:style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-12  text-center">
            <h1 class="mb-2 bread">{{ sobtopo[0].nome }}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section" style="padding-top: 70px;">
			<div class="container">
				<div class="row no-gutters justify-content-center mb-5">
          <div class="col-md-6 text-center heading-section ">
            <h2 class="mb-4">{{ sobtopo[0].referencia }}</h2>
            
          </div>
        </div>
        <div class="row">
			<div class="col-md-4" v-for="cadservi in cadservis" :key="cadservi.institucionalId">
        		<div class="project">
        			<div class="img rounded mb-4" :style="{ backgroundImage: 'url(' + $imgURL + cadservi.institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }"
					></div>
        			<div class="text w-100 text-center">
        				<span class="cat"> <router-link :to="'/servicodetalhe/' + cadservi.institucionalId + '/' + cadservi.nome.replaceAll(' ', '-').replaceAll('/', '') "
							 style="color: #ffff; ">Saiba mais</router-link></span>
        				<h3><router-link :to="'/servicodetalhe/' + cadservi.institucionalId + '/' + cadservi.nome.replaceAll(' ', '-').replaceAll('/', '') ">{{cadservi.nome}}</router-link></h3>
        			</div>
        		</div>
        	</div>
        </div>
	</div>
	</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'ServicosView',
  components: {},
  data: () => ({
    ecosystem: [],
	sobtopo: [],
	cadservis: [] ,

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Serviços - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
	  // servicos topo
	  axios.get('/institucional/telas/servicostopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	   //serviços
	   axios.get('/institucional/telas/cadastroservicos')
        .then(response => {
        
        this.cadservis = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

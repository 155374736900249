<template>
  <div>

    <section class="hero-wrap hero-wrap-2" v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
    :style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ sobtopo[0].nome}}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section" style="padding-top: 70px;">
			<div class="container">
				<div class="row">
          <div class="col-md-6 col-lg-4 " v-for="noticia in noticias" :key="noticia.institucionalId">
            <div class="blog-entry">
              <router-link :to="'/blogdetalhe/' + noticia.institucionalId + '/' + noticia.nome.replaceAll(' ', '-').replaceAll('/', '') "
              class="block-20 d-flex align-items-end" :style="{ backgroundImage: 'url(' + $imgURL + noticia.institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width:'100%' }">
              </router-link>
              <div class="text p-4">
                <h3 class="heading"><router-link :to="'/blogdetalhe/' + noticia.institucionalId + '/' + noticia.nome.replaceAll(' ', '-').replaceAll('/', '') " >{{ noticia.nome }} </router-link></h3>
                <p  style="text-align: justify;"></p>
                <div class="d-flex align-items-center mt-4">
	                <p class="mb-0"><router-link :to="'/blogdetalhe/' + noticia.institucionalId + '/' + noticia.nome.replaceAll(' ', '-').replaceAll('/', '') "
                    class="btn btn-primary">Leia Mais <span class="ion-ios-arrow-round-forward"></span></router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5" style="padding-bottom: 50px;">
        </div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogView',
  components: {
    moment
  },
  data: () => ({
    date: '2023-05-15',
    sobtopo: [],
	  noticias: [] ,

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
    carregaMetas() {
			useHead({
				title: 'Blog - Satis Brasil'
			})
		},
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY');
    },
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
     // servicos topo
	  axios.get('/institucional/telas/blogtopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	   //blog
	   axios.get('/institucional/telas/noticias')
        .then(response => {
        
        this.noticias = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem);
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style scoped>
</style>

<template>
  <div>
    <footer class="ftco-footer ftco-bg-dark ftco-section">
		<div class="container" >
		  <div class="row mb-5">
			<div class="col-md-6 col-lg-3" v-if="rodape!=''">
			  <div class="ftco-footer-widget mb-5">
				  <h2 class="ftco-heading-2">Contatos</h2>
				  <div class="block-23 mb-3">
					<ul>
					  <li><span class="icon icon-map-marker"></span><span class="text">{{ rodape[0].nome}}</span></li>
					  <li><a href="#"><span class="icon icon-phone"></span><span class="text">{{ rodape[0].referencia}}</span></a></li>
					  <li><a href="#"><span class="icon icon-envelope"></span><span class="text">{{ rodape[0].campoExtra1}}</span></a></li>
					</ul>
				  </div>
				  <div style="display: inline-flex;">
				  <div class=""><a v-if="redes!='' && redes[0].nome!=''" :href="redes[0].nome" target="_blank">
					<span class="icon-facebook" style="font-size:30px; margin-right: 20px"></span></a></div>
					<div class=""><a v-if="redes!='' && redes[0].referencia!=''" :href="redes[0].referencia" target="_blank">
					<span class="icon-instagram" style="font-size:30px; margin-right: 20px"></span></a></div>
					<div class=""><a v-if="redes!='' && redes[0].campoExtra1!=''" :href="redes[0].campoExtra1" target="_blank">
					<span class="icon-youtube" style="font-size:30px; margin-right: 20px"></span></a></div>
					<div class=""><a v-if="redes!='' && redes[0].campoExtra2!=''" :href="redes[0].campoExtra2" target="_blank">
					<span class="icon-linkedin" style="font-size:30px;"></span></a></div>
				</div>
			  </div>
			</div>
			<div class="col-md-6 col-lg-3">
			  <div class="ftco-footer-widget mb-5 ml-md-4">
				<h2 class="ftco-heading-2">Links Úteis</h2>
				<ul class="list-unstyled">
				  <li><router-link to="/"><span class="ion-ios-arrow-round-forward mr-2"></span>Home</router-link></li>
				  <li><router-link to="/satisbrasil"><span class="ion-ios-arrow-round-forward mr-2"></span>Satis Brasil</router-link></li>
				  <li><router-link to="/produtos"><span class="ion-ios-arrow-round-forward mr-2"></span>Produtos</router-link></li>
				  <li><router-link to="/servicos"><span class="ion-ios-arrow-round-forward mr-2"></span>Serviços</router-link></li>
				</ul>
				</div>
			</div>
			<div class="col-md-6 col-lg-3">
			  <div class="ftco-footer-widget mb-5">
				<h2 class="ftco-heading-2">Links Úteis</h2>
				<ul class="list-unstyled">
				  <li><router-link to="/dicassaude"><span class="ion-ios-arrow-round-forward mr-2"></span>Dicas de Saúde</router-link></li>
				  <li><router-link to="/temperosatis"><span class="ion-ios-arrow-round-forward mr-2"></span>Tempero Satis</router-link></li>
				  <li><router-link to="/contato"><span class="ion-ios-arrow-round-forward mr-2"></span>Contato</router-link></li>
				</ul>
			  </div>
			</div>
			<div class="col-md-6 col-lg-3">
			  <div class="ftco-footer-widget mb-5">
				  <h2 class="ftco-heading-2">LGPD</h2>
				<ul class="list-unstyled">
				  <li v-for="lgpd in lgpds" :key="lgpd.institucionalId">
					<router-link :to="'/informacoes/' + lgpd.institucionalId + '/' + lgpd.nome.replaceAll(' ', '-').replaceAll('/', '') ">
						<span class="ion-ios-arrow-round-forward mr-2"></span>{{ lgpd.nome }}</router-link>
					</li>
				</ul>
			  </div>
			</div>
		  </div>
		  <div class="row">
			<div class="col-md-12 text-center">
			  <a href="https://avancedigital.com.br/" target="_blank"><img src="/images/avanceBranco.png" alt=""></a>
			</div>
		  </div>
		</div>
	  </footer>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';

export default {
  name: 'FooterPage',
  components: {},
  data: () => ({
    ecosystem: [],
	rodape: [],
	lgpds: [],
	redes: [],

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
	// Rodapé
	axios.get('/institucional/telas/informacoes')
        .then(response => {
        
        this.rodape = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	  // LGPD
	  axios.get('/institucional/telas/cadastrolgpd')
        .then(response => {
        
        this.lgpds = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem).reverse();
      })
      .catch(error => {
        console.error(error);
      });
	   // redes
	   axios.get('/institucional/telas/redes')
        .then(response => {
        
        this.redes = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

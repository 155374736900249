<template>
  <div>
    <section class="hero-wrap hero-wrap-2"  v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
    :style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ sobtopo[0].nome}}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section" style="padding-top: 70px;" v-for="menu in caddicas" :key="menu.institucionalId">
			<div class="container">
				<div class="row">
          <div class="col-lg-8 ">
            <h2 class="mb-3" style="color:#333">{{ menu.nome }}</h2>
              <img v-if="menu.institucionalImg!=''" style="width: 100%;" :src="$imgURL + menu.institucionalImg[0].urlImagem"/>
            <p v-html="texto" style="text-align: justify; color: #000000; padding-bottom: 50px;"></p>
          </div> <!-- .col-md-8 -->

          <div class="col-lg-4 sidebar ">
            <div class="sidebar-box ">
              <h3>{{ menu.nome }}</h3>
              <div class="block-21 mb-4 d-flex">
                <router-link class="blog-img mr-4" :to="'/dicassaude/' + menu.institucionalId + '/' + menu.nome.replaceAll(' ', '-').replaceAll('/', '')"
                :style="{ backgroundImage: 'url(' + $imgURL + imagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }"></router-link>
                <div class="text">
                  <h3 class="heading"><router-link :to="'/dicassaude/' + menu.institucionalId + '/' + menu.nome.replaceAll(' ', '-').replaceAll('/', '') " >{{titulo}}</router-link></h3>
                </div>
              </div>
            </div>
          </div><!-- END COL -->
        </div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'DicasSaudeView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    return {
      id
    };
  },
  components: {},
  data: () => ({
    dialog: false,
    sobtopo: [],
    caddicas:[],
    titulo:'',
	  imagem: [],
	  texto: '',

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Dicas de Saúde - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
     // dicas topo
     axios.get('/institucional/telas/dicastopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
       // dicas cadastro
	   axios.get('/institucional/telas/cadastrodicas')
        .then(response => {
        this.caddicas = response.data;
		if (this.id){
			let dicas=this.caddicas.filter(x=> x.institucionalId==this.id);
			this.titulo=dicas[0].nome;
			this.texto=dicas[0].descricao1;
			this.imagem=dicas[0].institucionalImg[0].urlImagem;
		}else{
			this.titulo= this.caddicas[0].nome;
			this.texto= this.caddicas[0].descricao1;
			this.imagem= this.caddicas[0].institucionalImg[0].urlImagem;
		}
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <section class="hero-wrap hero-wrap-2" v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
    :style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{sobtopo[0].nome}}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section contact-section" style="padding-top: 70px;" v-if="infocontato!=''">
      
      <div class="container">
        <div class="row d-flex mb-5 contact-info justify-content-center">
        	<div class="col-md-10">
        		<div class="row mb-5">
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-map-o"><br>Endereço:</span>
			          	</div>
			            <p v-html="infocontato[0].descricao1"></p>
			          </div>
		          </div>
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-tablet"><br>Telefone:</span>
			          	</div>
			            <p v-html="infocontato[0].descricao2"></p>
			          </div>
		          </div>
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-envelope-o"><br>E-mail:</span>
			          	</div>
			            <p v-html="infocontato[0].descricao3"></p>
			          </div>
		          </div>
		        </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-no-pb ftco-no-pt" v-if="infocontato!=''">
    	<div class="container-fluid px-0">
    		<div class="row justify-content-center">
        	<div class="col-md-12">
        		<div>
              <iframe :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + infocontato[0].nome "
              width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe>
            </div>
        	</div>
        </div>
    	</div>
    </section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'ContatoView',
  components: {
  },
  data: () => ({
   teste:[],
   sobtopo: [],
   infocontato: [],

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Contato - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
     // Contato topo
     axios.get('/institucional/telas/contatotopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
       // Info contato
       axios.get('/institucional/telas/infocontato')
        .then(response => {
        
        this.infocontato = response.data;
        console.log('this.infocontato')
        console.log(this.infocontato)
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

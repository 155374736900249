<template>
  <v-app>
    <v-main>
      <Header/>
      <router-view :key="$route.fullPath"/>
      <FooterPage/>
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage
  },
  data: () => ({
    ecosystem: []
  }),
  methods: {

  },
  created () {

  }
}
</script>
<style>
  @import "@/assets/css/open-iconic-bootstrap.min.css";
  @import "@/assets/css/animate.css";
  @import "@/assets/css/owl.theme.default.min.css";
  @import "@/assets/css/magnific-popup.css";
  @import "@/assets/css/aos.css";
  @import "@/assets/css/ionicons.min.css";
  @import "@/assets/css/flaticon.css";
  @import "@/assets/css/icomoon.css";
  @import "@/assets/css/style.css";


  @media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
    .tamCar {
  height: 250px !important;
}
  .tamFonteSlide {
    color:#fff;
    font-size: 30px;
    font-weight: 900;
}
.textoSlide{
  font-size: 17px !important;
  color: #336699;
  text-shadow: 1px 1px #fff;
}
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
    .tamCar {
  height: 400px !important;
}
.tamFonteSlide {
    color:#fff;
    font-size: 70px;
    font-weight: 900;
}
.textoSlide{
  font-size: 40px !important;
  color: #336699;
  text-shadow: 1px 1px #fff;
}
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
    .tamCar {
  height: 600px !important;
}
.tamFonteSlide {
    color:#fff;
    font-size: 70px;
    font-weight: 900;
}
.textoSlide{
  font-size: 40px !important;
  color: #336699;
  text-shadow: 1px 1px #fff;
}
.infLogo {
  width: 320px;
}
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
    .tamCar {
  height: 500px !important;
}
.tamFonteSlide {
    color:#fff;
    font-size: 70px;
    font-weight: 900;
}
.textoSlide{
  font-size: 60px !important;
  color: #336699;
  text-shadow: 1px 1px #fff;
}
}
</style>

<template>
  <div >
    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="3" :breakpoints="{
      768: { slidesPerView: 3 }, 0: { slidesPerView: 1 }
    }" class="carousel-testimony owl-carousel">
      <SwiperSlide v-for="produto in produtos" :key="produto.institucionalId">
        <div class="item" style="padding: 10px;">
          <div class="testimony-wrap d-flex" style="min-height: 120px;">
            <div class=""  v-if="produto.institucionalImg!=''"
            :style="{ backgroundImage: 'url(' + $imgURL + produto.institucionalImg[0].urlImagem + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', heigth: '60px',width: '60px' }">
            </div>
            <div class="text pl-4">
              <span class="quote d-flex align-items-center justify-content-center">
              </span>
              <p class="name" style="color: #ab3331;">{{produto.nome}}</p>
              <p style="text-align: justify;" v-html="produto.resumo1"></p>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';

export default {
  name: 'PageCarrosel',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    drawer: null,
    produtos: [],
  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created() {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    // Tras Carrossel
		axios.get('/institucional/telas/cadastroprodutos')
			.then(response => {

				this.produtos = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem);
			})
			.catch(error => {
				console.error(error);
			});
  }
}
</script>

<style scoped></style>

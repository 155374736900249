<template>
  <div>
    <section class="hero-wrap hero-wrap-2"  v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
    :style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{sobtopo[0].nome}}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section" style="padding-top: 70px;" >
			<div class="container">
				<div class="row">
          <div class="col-lg-8 " >
            <h2 class="mb-3" style="color: #333">{{titulo }}</h2>
            <img v-if="imagem!=''" style="width: 100%;" :src="$imgURL + imagem"/>
            <p  v-html="texto" style="text-align: justify; color: #000000; padding-bottom: 50px;"></p>
          </div> <!-- .col-md-8 -->

          <div class="col-lg-4 sidebar " >
            <div class="sidebar-box " >
              <h3>Últimas postagens</h3>
              <div class="block-21 mb-4 d-flex"  v-for="menu in tempsatis" :key="menu.institucionalId">
                <router-link class="blog-img mr-4" :to="'/temperosatis/' + menu.institucionalId + '/' + menu.nome.replaceAll(' ', '-').replaceAll('/', '') "
                :style="{ backgroundImage: 'url(' + $imgURL + menu.institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }"></router-link>
                <div class="text">
                  <h3 class="heading"><router-link :to="'/temperosatis/' + menu.institucionalId + '/' + menu.nome.replaceAll(' ', '-').replaceAll('/', '') ">{{ menu.nome }}</router-link></h3>
                </div>
              </div>
            </div>
          </div><!-- END COL -->
        </div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'TemperoSatisView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    return {
      id
    };
  },
  components: {
    moment
  },
  data: () => ({
    date: '2023-05-15',
    sobtopo: [],
    tempsatis:[],
    titulo:'',
	  imagem: [],
	  texto: '',

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
    carregaMetas() {
			useHead({
				title: 'Temperos - Satis Brasil'
			})
		},
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY');
    },
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
     // servicos topo
	  axios.get('/institucional/telas/temperotopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
       // tempero cadastro
	   axios.get('/institucional/telas/cadastrotempero')
        .then(response => {
        this.tempsatis = response.data;
		if (this.id){
			let temp=this.tempsatis.filter(x=> x.institucionalId==this.id);
			this.titulo=temp[0].nome;
			this.texto=temp[0].descricao1;
			this.imagem=temp[0].institucionalImg[0].urlImagem;
		}else{
			this.titulo= this.tempsatis[0].nome;
			this.texto= this.tempsatis[0].descricao1;
			this.imagem= this.tempsatis[0].institucionalImg[0].urlImagem;
		}
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style scoped>
</style>

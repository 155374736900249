<template>
  <div>
    <section class="hero-wrap hero-wrap-2" v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''" 
    :style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ sobtopo[0].nome }}</h1>
          </div>
        </div>
      </div>
    </section>
		
		<section class="ftco-section" style="padding-top: 70px;"  v-if="blogdet!=''">
			<div class="container">
				<div class="row">
          <div class="col-lg-8 ">
            <h2 class="mb-3" style="color: #333;">{{blogdet[0].nome}}</h2>
              <img v-if="blogdet[0].institucionalImg!=''" :src="$imgURL + blogdet[0].institucionalImg[0].urlImagem" alt="" class="img-fluid">
            <p v-html="blogdet[0].descricao1" style="text-align: justify; color: #000000; padding-bottom: 50px;"></p>
          </div> 
          <div class="col-md-12" style="text-align: center; padding-top: 50px; font-size: 20px; font-weight: 700;">
                <span><router-link to="/blog"> Voltar</router-link></span>
              </div><!-- .col-md-8 -->
        </div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogDetalheView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const nome = route.params.nome;
    return {
      id,nome
    };
  },
  components: {},
  data: () => ({
    ecosystem: [],
    sobtopo: [],
    blogdet: [],

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: this.nome + ' Blog Detalhe - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
     // Blog Topo
     axios.get('/institucional/telas/blogtopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
      //Blog detalhes
      axios.get('/institucional/telas/noticias')
        .then(response => {
        
        this.blogdet = response.data.filter(x => x.institucionalId==this.id);

      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

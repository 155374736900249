import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import moment from 'moment';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { createHead } from '@vueuse/head';

SwiperCore.use([Navigation, Pagination]);

loadFonts();

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(vuetify);
app.use(VueViewer);
app.use(head);
app.config.globalProperties.$moment = moment;
axios.defaults.baseURL = 'https://controle.satisbrasil.com.br/api';
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$imgURL = 'https://controle.satisbrasil.com.br';
app.config.globalProperties.$mensagemSucesso=function(mensagem){
    Swal.fire(" ",mensagem, "success");
}
app.config.globalProperties.$mensagemErro=function(mensagem){
    Swal.fire(" ",mensagem, "error");
}
app.mount('#app');
<template>
  <div>
    <section class="hero-wrap hero-wrap-2" :style="{ backgroundImage: 'url(' + $imgURL + lgpdtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{ lgpdtopo[0].nome }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter">
			<div class="container consult-wrap">
				<div class="row d-flex align-items-stretch">
					<div class="col-md-12 wrap-about  py-md-5 pl-md-5">
						<div class="heading-section mb-4">
							<h2 style="color: #333;">{{ lgpdcadastro[0].nome }}</h2>
						</div>
						<p  v-html="lgpdcadastro[0].descricao1" style="text-align: justify;"></p>
					</div>
				</div>
			</div>
		</section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'InformacoesView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const nome = route.params.nome;
    return {
      id,nome
    };
  },
  components: {},
  data: () => ({
    ecosystem: [],
    lgpdtopo: [],
    lgpdcadastro: [],

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: this.nome + ' Informações - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
      //lgpd topo
      axios.get('/institucional/telas/lgpdtopo')
        .then(response => {
        
        this. lgpdtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
       //lgpd cadastro
       axios.get('/institucional/telas/cadastrolgpd')
        .then(response => {
        
        this. lgpdcadastro = response.data.filter(x => x.institucionalId==this.id);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>

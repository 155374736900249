<template>
	<div>

		<v-carousel hide-delimiters class="tamCar" v-if="sliders.length > 0">
			<v-carousel-item v-for="slider in sliders" :key="slider.institucionalId">

				<v-sheet height="100%"
					:style="{ backgroundImage: 'url(' + $imgURL + slider.institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
					<div class="overlay"
						style=" position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: #000000; opacity: .6; ">
					</div>
					<div class="d-flex fill-height justify-center align-center ">
						<div class="text-h2">
							<div class="col-md-11 text-center slider-item slider-text h1 ">
								<h1 class="mb-4 tamFonteSlide">{{ slider.nome }}</h1>
								<p><router-link :to="slider.linkBtn1"
										class="btn btn-primary px-4 py-3 mt-3 hidden-xs hidden-sm">{{ slider.textoBtn1 }}</router-link>
								</p>
							</div>
						</div>
					</div>
				</v-sheet>
			</v-carousel-item>


		</v-carousel>

		<section class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter"
			style="padding-top: 50px;" v-if="descricaoSobre != ''">
			<div class="container consult-wrap">
				<div v-if="descricaoSobre != ''" class="row d-flex align-items-stretch">
					<div class="col-md-6 wrap-about align-items-stretch d-flex">
						<div v-if="descricaoSobre[0].institucionalImg != ''" class="img"
							:style="{ backgroundImage: 'url(' + $imgURL + descricaoSobre[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '410px' }">
						</div>
					</div>
					<div class="col-md-6 wrap-about  py-md-5 pl-md-5">
						<div class="heading-section mb-4">
							<h2 style="color: #333;">{{ descricaoSobre[0].nome }}</h2>
						</div>
						<p v-html="descricaoSobre[0].descricao1" style="text-align: justify;color: #000000;"></p>
						<p><router-link :to="descricaoSobre[0].linkBtn1"
								class="btn btn-primary px-4 py-3 mt-3">{{ descricaoSobre[0].textoBtn1 }}</router-link></p>
						<div class="row mt-5">
						</div>
					</div>
				</div>
			</div>
		</section>
		<section v-if="callhome != '' && callhome[0].institucionalImg != ''" class="ftco-intro ftco-no-pb img"
			:style="{ backgroundImage: 'url(' + $imgURL + callhome[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ">
						<h2 class="mb-3 mb-md-0" style="text-align: center;">{{ callhome[0].nome }}</h2>
					</div>
					<div class="col-lg-3 col-md-4 ">
						<p><router-link :to="callhome[0].linkBtn1"
								class="btn btn-primary px-4 py-3 mt-3">{{ callhome[0].textoBtn1 }}</router-link></p>
					</div>
				</div>
			</div>
		</section>
		<section class="ftco-section testimony-section" style="padding-top: 70px;padding-bottom: 150px;">
			<div class="container-fluid px-md-5">
				<div v-if="titprodhome != ''" class="row justify-content-center mb-5">
					<div class="col-md-8 text-center heading-section ">
						<h2 class="mb-4" style="color: #333;">{{ titprodhome[0].nome }}</h2>
						<p v-html="titprodhome[0].descricao1" style="text-align: center; color: #000000;"></p>
						<p style="color:#ab3331;"><router-link
								:to="titprodhome[0].linkBtn1">{{ titprodhome[0].textoBtn1 }}</router-link></p>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-12">
						<carrosel></carrosel>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section ftco-no-pt ftco-no-pb ftco-consult">
			<div class="container">
				<div class="row d-flex no-gutters align-items-stretch consult-wrap">
					<div class="col-md-12 wrap-about  align-items-stretch d-flex">
						<div class="bg-white p-5" v-if="itens != ''">
							<h2 class="mb-4" style="text-align: center; color: #333;">{{ itens[0].nome }}</h2>
							<p v-html="itens[0].descricao1" style="text-align: center; color: #000000;">
							</p>
							<div class="row" v-if="itensHome.length > 0">
								<div class="col-lg-3" v-for="itemHome in itensHome" :key="itemHome.institucionalId">
									<div class="services">
										<div class="icon mt-2 d-flex align-items-center" style="justify-content: center;">
											<img v-if="itemHome.institucionalImg != ''"
												:src="$imgURL + itemHome.institucionalImg[0].urlImagem"
												style="width: 70px;">
										</div>
										<div class="text media-body">
											<h3 style="text-align: center; color: #ab3331;">{{ itemHome.nome }}</h3>
											<p v-html="itemHome.descricao2" style="text-align: center; color: #000000;"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import moment from 'moment'
import carrosel from './../components/PageCarrosel.vue'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
	name: "HomeView",
	components: {
		moment,
		carrosel
	},
	data: () => ({
		date: '2023-05-15',
		sliders: [],
		descricaoSobre: [],
		callhome: [],
		titprodhome: [],
		itens: [],
		itensHome: [],

	}),
	methods: {
		// Aqui vai a chamada das funções, que são validações.
		formattedDate(value) {
			return this.$moment(value).format('DD/MM/YYYY');
		},
		carregaMetas() {
			useHead({
				title: 'Home - Satis Brasil'
			})
		}
	},
	created() {
		// É quando roda a tela, busca a informação e carrega em variáveis que estipular.
		// Tras sliders
		this.carregaMetas();
		axios.get('/institucional/telas/slider')
			.then(response => {

				this.sliders = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem);
			})
			.catch(error => {
				console.error(error);
			});
		// sobre home
		axios.get('/institucional/telas/sobrehome')
			.then(response => {

				this.descricaoSobre = response.data;
			})
			.catch(error => {
				console.error(error);
			});
		//call home
		axios.get('/institucional/telas/callaction')
			.then(response => {

				this.callhome = response.data;
			})
			.catch(error => {
				console.error(error);
			});

		//Titulo produto home
		axios.get('/institucional/telas/tituloprodutoshome')
			.then(response => {

				this.titprodhome = response.data;
			})
			.catch(error => {
				console.error(error);
			});
		//  itens titulo home
		axios.get('/institucional/telas/tituloitens')
			.then(response => {

				this.itens = response.data;
			})
			.catch(error => {
				console.error(error);
			});

		//  itens home 
		axios.get('/institucional/telas/itens')
			.then(response => {

				this.itensHome = response.data.filter(x => x.visivel).sort((a, b) => a.ordem - b.ordem);;
			})
			.catch(error => {
				console.error(error);
			});
	},
};
</script>

<style>.swiper-pagination-bullet {
	background-color: #ab3331 !important;
}</style>

<template>
  <div>
    <!-- Aqui vai menu computador-->
   
    <div class="hidden-sm hidden-xs">
      <div class="bg-top navbar-light d-flex flex-column-reverse">
    	<div class="container py-3">
    		<div class="row no-gutters d-flex align-items-center align-items-stretch">
    			<div class="col-md-4 d-flex align-items-center py-4">
    				<router-link to="/"><img src="/images/logo.png" style="width: 200px;" alt=""></router-link>
					<logo class="jpg"></logo>
    			</div>
	    		<div class="col-lg-8 d-block" v-if="topo!=''">
		    		<div class="row d-flex">
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-paper-plane"></span></div>
					    	<div class="text">
					    		<span>E-mail:</span>
						    	<span>{{ topo[0].referencia}}</span>
						    </div>
					    </div>
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-call"></span></div>
						    <div class="text">
						    	<span>Telefone:</span>
						    	<span>{{ topo[0].nome}}</span>
						    </div>
					    </div>
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-time"></span></div>
						    <div class="text">
						    	<span>Horário de Atendimento</span>
						    	<span v-html="topo[0].resumo1"></span>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
		  </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
	    <div class="container d-flex align-items-center">
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
	        <span class="oi oi-menu"></span> Menu
	      </button>
	      <div class="collapse navbar-collapse" id="ftco-nav">
	        <ul class="navbar-nav mr-auto">
	        	<li class="nav-item"><router-link to="/" class="nav-link">Home</router-link></li>
	        	<li class="nav-item"><router-link to="/satisbrasil" class="nav-link">Satis Brasil</router-link></li>
				    <li class="nav-item"><router-link to="/produtos" class="nav-link">Produtos</router-link></li>
				    <li class="nav-item"><router-link to="/servicos" class="nav-link">Serviços</router-link></li>
	        	<li class="nav-item"><router-link to="/dicassaude" class="nav-link">Dicas de Saúde</router-link></li>
				    <li class="nav-item"><router-link to="/temperosatis" class="nav-link">Tempero Satis</router-link></li>
	        	<li class="nav-item"><router-link to="/blog" class="nav-link">Blog</router-link></li>
	          <li class="nav-item"><router-link to="/contato" class="nav-link">Contato</router-link></li>
	        </ul>
	      </div>
	    </div>
	  </nav>
    </div>
   
    <!-- Aqui vai menu para celular-->
    <div class="hidden-md hidden-lg">
      <div class="container" style="padding-bottom: 15px;">
        <div class="row">
          <div class="col-4" style="padding-top: 15px;">
            <v-menu width="300" style="padding-left: 20px;">
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="/">
                  <v-list-item-title class="menuTamanho">Home</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/satisbrasil">
                  <v-list-item-title class="menuTamanho">Satis Brasil</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/produtos">
                  <v-list-item-title class="menuTamanho">Produtos</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/servicos">
                  <v-list-item-title class="menuTamanho">Serviços</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/dicassaude">
                  <v-list-item-title class="menuTamanho">Dicas de Saúde</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/temperosatis">
                  <v-list-item-title class="menuTamanho">Tempero Satis</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/blog">
                  <v-list-item-title class="menuTamanho">Blog</v-list-item-title>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/contato">
                  <v-list-item-title class="menuTamanho">Contato</v-list-item-title>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          </div>
          <div class="col-8" style="display:flex; align-items: end; justify-content: end;">
            <router-link to="/" class="navbar-brand">
          <img src="/images/logo.png" style="width: 150px;" /></router-link>
          </div>
        </div>
      </div>
</div>

  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';

export default {
  name: 'PageHeader',
  components: {},
  data: () => ({
    topo: [],

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
     // Topo
     axios.get('/institucional/telas/topo')
        .then(response => {
        
        this.topo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
.menuTamanho{
  font-size: 20px;
  text-align: center;
}
</style>

<template>
  <div>
    <section class="hero-wrap hero-wrap-2" v-if="sobtopo!='' && sobtopo[0].institucionalImg!=''"
	:style="{ backgroundImage: 'url(' + $imgURL + sobtopo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{sobtopo[0].nome}}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-no-pb" style="padding-top: 70px;">
    	<div class="container">
    		<div class="row justify-content-center mb-5">
          <div class="col-md-8 text-center heading-section ">
            <h2 class="mb-4">{{ sobtopo[0].referencia }}</h2>
            <p></p>
          </div>
        </div>
  			<div class="row tabulation mt-4 " style="padding-bottom: 120px;">
  				<div class="col-md-4">
						<ul class="nav nav-pills nav-fill d-md-flex d-block flex-column">
						  <li class="nav-item text-left"  v-for="menu in cadprodutos" :key="menu.institucionalId" >
							<router-link class="nav-link py-4" :to="'/produtos/' + menu.institucionalId + '/' + menu.nome.replaceAll(' ', '-').replaceAll('/', '') ">
								<img v-if="menu.institucionalImg!=''" :src="$imgURL + menu.institucionalImg[0].urlImagem" style="width: 50px; padding-right: 10px;"/>{{ menu.nome }}</router-link>
						  </li>
						</ul>
					</div>
					<div class="col-md-8">
						<div class="tab-content">
						  <div class="tab-pane container p-0 active" id="services-1">
							<h3><a href="">{{titulo}}</a></h3>
						  	<div class="img" :style="{ backgroundImage: 'url(' + $imgURL + imagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }"></div>
						  	<p v-html="texto" style="text-align: justify; color: black;"></p>
						  </div>
						</div>
					</div>
				</div>
    	</div>
    </section>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'ProdutosView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    return {
      id
    };
  },
  components: {},
  data: () => ({
    ecosystem: [],
	sobtopo: [],
	cadprodutos: [],
	titulo:'',
	imagem: [],
	texto: '',

  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Produtos - Satis Brasil'
			})
		},
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    this.carregaMetas();
	 // produtos topo
     axios.get('/institucional/telas/produtostopo')
        .then(response => {
        
        this.sobtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
	   // produtos 
	   axios.get('/institucional/telas/cadastroprodutos')
        .then(response => {
        
        this.cadprodutos = response.data;
		if (this.id){
			let produto=this.cadprodutos.filter(x=> x.institucionalId==this.id);
			this.titulo=produto[0].nome;
			this.texto=produto[0].descricao1;
			this.imagem=produto[0].institucionalImg[1].urlImagem;
		}else{
			this.titulo= this.cadprodutos[0].nome;
			this.texto= this.cadprodutos[0].descricao1;
			this.imagem= this.cadprodutos[0].institucionalImg[1].urlImagem;
		}
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
</style>
